html {
  font-size: 13px; 
}

.cursor-pointer {
  cursor: pointer;
}

table th {
  border-top: 0px !important;
}

.loading {
  animation: blinker 0.5s cubic-bezier(1, 0, 0, 1) infinite alternate; 
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.actionbutton {
  font-size: 0.8em;
  border: 1px solid #bbbbbb;
  border-radius: 3px;
  padding: 1px 3px;
  color: #bbbbbb;
}

.actionbutton:hover {
  border: 1px solid #333333;
  color: #333333;
}

.btn-xs {
  padding: 0.1rem 0.3rem;
  font-size: .8rem;
  line-height: 1.2;
  border-radius: 0.2rem;
}
